<template>
    <DisclosurePanel v-if="agenda" id="agenda">
        <div class="flex items-center text-base font-medium at1024:space-x-2">
            <clock-icon
                class="hidden h-7 w-7 shrink-0 stroke-1 at1024:inline-block"
            ></clock-icon>
            <span>A breakdown of how the event will go</span>
        </div>

        <description-copy-block :description="agenda"></description-copy-block>
    </DisclosurePanel>
</template>

<script setup>
import { DisclosurePanel } from '@headlessui/vue';
import { ClockIcon } from '@heroicons/vue/outline';
import DescriptionCopyBlock from 'components/ListingDetail/DescriptionCopyBlock.vue';
import { computed, inject } from 'vue';

const product = inject('product');

const agenda = computed(() => product.agenda?.trim());
</script>

<style scoped></style>
